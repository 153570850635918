<template>
  <div class="group">
    <input :value="modelValue" @input="changeValue" class="input" type="text" required>
    <span class="highlight"></span>
    <span class="bar"></span>
    <label>{{ placeholder }}</label>
  </div>
</template>

<script>
export default {
  name: 'MaterialInput',
  props: ['modelValue', 'placeholder'],
  emits: ['update:modelValue'],
  methods: {
    changeValue (e) {
      this.$emit('update:modelValue', e.target.value)
    }
  }
}
</script>

<style lang="scss" scoped>
/* form starting stylings ------------------------------- */
.group {
  @apply relative mt-6 mx-1;
}
.input {
  @apply text-base w-full border-0;
  padding:10px 10px 10px 5px;
  border-bottom:1px solid #757575;
}
.input:focus{ outline:none; }

/* LABEL ======================================= */
label {
  @apply text-base left-3 top-3 absolute text-base text-gray-400;
  pointer-events:none;
  transition:0.2s ease all;
  -moz-transition:0.2s ease all;
  -webkit-transition:0.2s ease all;
}

/* active state */
.input:focus ~ label, .input:valid ~ label {
  top:-20px;
  @apply text-gray-800 text-base font-bold left-1;
}

/* BOTTOM BARS ================================= */
.bar {
  @apply relative block w-full;
}

.bar:before, .bar:after {
  content:'';
  @apply bg-blue-700 absolute h-px;
  transition:0.2s ease all;
  -moz-transition:0.2s ease all;
  -webkit-transition:0.2s ease all;
}
.bar:before {
  left:50%;
}
.bar:after {
  right:50%;
}

/* active state */
.input:focus ~ .bar:before, .input:focus ~ .bar:after {
  width:50%;
}

/* HIGHLIGHTER ================================== */
.highlight {
  height:60%;
  width:100px;
  top:25%;
  left:0;
  pointer-events:none;
  @apply absolute opacity-50;
}

/* active state */
.input:focus ~ .highlight {
  -webkit-animation:inputHighlighter 0.3s ease;
  -moz-animation:inputHighlighter 0.3s ease;
  animation:inputHighlighter 0.3s ease;
}

/* ANIMATIONS ================ */
@-webkit-keyframes inputHighlighter {
  from { background:#5264AE; }
  to { width:0; background:transparent; }
}
@-moz-keyframes inputHighlighter {
  from { background:#5264AE; }
  to { width:0; background:transparent; }
}
@keyframes inputHighlighter {
  from { background:#5264AE; }
  to { width:0; background:transparent; }
}
</style>
